<template>
  <div>
    <NotifyModal
      :show="addToNotifyModal"
      :product-sku="notifyModalProduct && notifyModalProduct.sku"
      @close="closeNotifyModal"
    />
    <b-modal
      :id="'modal' + product.sku"
      size="lg"
      v-model="addToCartModal"
      content-class="product-popup group"
      centered
      :hide-footer="true"
    >
      <div class="product-popup-body">
        <img :src="product.thumbnail.url" />
        <div class="info-popup">
          <h2>{{ product.name }}</h2>
          <div class="info-popup-wrap">
            <div class="info-wrap">
              <p class="my-price">
                {{ formatCurrency(priceRange.maximum_price.final_price.value) }}
              </p>
              <p>{{ $t("number") }} : {{ totalQty }}</p>
            </div>
            <div class="action-btn">
              <b-link class="c-btn c-btn-primary" @click="clickOk">{{
                $t("continue_buying")
              }}</b-link>
              <b-link class="btn-link" @click="clickCancel"
                ><b-icon icon="chevron-left"></b-icon>
                {{ $t("continue_shopping") }}</b-link
              >
            </div>
          </div>
        </div>
      </div>

      <!-- <img :src="product.thumbnail.url" />
      <p class="my-4">{{ product.name }}</p>
      <p class="my-price">
        {{ formatCurrency(priceRange.maximum_price.final_price.value) }}
      </p> -->
    </b-modal>

    <!-- compare model -->
    <b-modal
      :id="`modal-compare`"
      v-model="addToCompareModal"
      size="lg"
      content-class="product-popup product-compare-popup"
      centered
      :hide-footer="true"
    >
      <div class="product-popup-body">
        <img :src="product.thumbnail.url" />

        <div class="info-popup">
          <h2>{{ product.name }}</h2>
          <div class="info-popup-wrap">
            <div class="info-wrap">
              <p class="my-price">
                <span class="d-block product-card--price">
                  {{
                    formatCurrency(
                      product.price_range.minimum_price.final_price.value
                    )
                  }}
                </span>
              </p>

              <p>{{ $t("number") }} : {{ quantity }}</p>
            </div>
            <div class="action-btn">
              <b-link class="c-btn c-btn-primary" @click="clickOkCompare">{{
                $t("compare")
              }}</b-link>
              <b-link class="btn-link" @click="clickCancelCompare"
                ><b-icon icon="chevron-left"></b-icon>
                {{ $t("continue_shopping") }}</b-link
              >
            </div>
          </div>

          <!-- <p>{{ product.name }}</p> -->
        </div>
      </div>
    </b-modal>
    <!-- compare model -->

    <div class="price-part">
      <div class="out-stock-box" :class="stockStatusClass">
        <h3>{{ $t(isInStock ? "In Stock" : "Out of Stock") }}</h3>
      </div>
    </div>

    <div class="group-product-wrap" v-if="productGrouped.length">
      <div class="group-product-header">
        <div class="gp-name-title">{{ $t("Product name") }}</div>
        <div class="gp-price-title">{{ $t("Price") }}</div>
        <div class="gp-quantity-title">{{ $t("number") }}</div>
      </div>
      <div class="group-product-body">
        <div
          class="group-product-list"
          v-for="product in productGrouped"
          :key="product.sku"
        >
          <div class="gp-name">
            <div>{{ product.name.split("|")[0] }}</div>
            <div v-if="getNamePart2(product) !== ''">
              {{ getNamePart2(product) }}
            </div>
          </div>
          <div class="gp-price">
            {{
              formatCurrency(
                product.price_range.minimum_price.final_price.value
              )
            }}
          </div>
          <div class="gp-quantity">
            <div
              class="quantity-input"
              v-if="product.stock_status == 'IN_STOCK'"
            >
              <b-link
                class="step-down-btn"
                @click="quantityDown(product.sku)"
              ></b-link>
              <input
                type="number"
                min="1"
                :value="productsQty[product.sku] ? productsQty[product.sku] : 0"
                name="quantity"
                ref="inputQuantity"
                disabled
              />
              <b-link
                class="step-up-btn"
                @click="quantityUp(product.sku)"
              ></b-link>
            </div>
            <div
              v-else
              class="cart-btn"
              @click="addToNotify(product.sku)"
              key="notify-btn"
            >
              <i class="fas fa-envelope"></i>
            </div>
          </div>
        </div>
      </div>
    </div>

    <button
      v-if="isInStock"
      v-on:click="addToCart"
      v-bind:disabled="btnDisable"
      :style="buttonClass"
      class="product-parameter-submit-btn"
    >
      <i class="fas fa-shopping-bag"></i>
      {{ $t("add_to_cart") }}
    </button>
    <!-- <button
      v-else
      v-on:click="addToNotify"
      class="product-parameter-submit-btn"
    >
      <i class="fas fa-envelope"></i>
      {{ $t("notify_btn") }}
    </button> -->

    <div class="comon-wrap">
      <div class="whish-list-add" @click.stop="toggleIsInWishList">
        <div
          class="heart-icon"
          v-if="$store.getters['user/isProductInWishlist'](product.sku)"
          :class="{ selected: isInWishList }"
        >
          <b-icon icon="heart-fill"></b-icon>
        </div>
        <div class="heart-icon" v-else>
          <b-icon icon="heart"></b-icon>
        </div>
        <span>{{ $t("add_to_wish_list") }}</span>
      </div>
      <div class="compare-list" @click="onCompareClick">
        <div class="compare-icon" v-if="!isProductInCompare">
          <!-- v-if="isProductInCompare ? '' : 'added'" -->
          <b-icon icon="square"></b-icon>
        </div>
        <div class="compare-icon added" v-else>
          <b-icon icon="check-square-fill"></b-icon>
        </div>

        <span>{{ $t("show_compararison") }}</span>
      </div>
    </div>
    <span
      class="delivery-info d-block"
      v-if="product.DeliveryTime != null"
      :style="'color: ' + product.DeliveryTime.color + '!important'"
    >
      {{ product.DeliveryTime.long }}</span
    >
    <div
      class="product-extra-info"
      v-html="product.short_description.html"
    ></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Logger } from "@storefront/core/lib/logger";
import NotifyModal from "@/base/core/components/common/NotifyModal.vue";

export default {
  name: "ProductParametersGrouped",
  components: {
    NotifyModal,
  },
  props: {
    parameters: { type: Object },
    isInWishList: { type: Boolean },
  },
  computed: {
    ...mapGetters({
      product: "product/getCurrentProduct",
      compareList: "compare/getProductCompare",
      cartItems: "cart/getCartItems",
    }),
    productBrand() {
      let productBrand = "";
      if (this.product.information_attributes) {
        const brand = this.product.information_attributes.find(
          (item) => item.attribute_code == "merk"
        );
        if (brand) {
          productBrand = brand.value;
        }
      }
      return productBrand;
    },
    productGrouped() {
      if (this.product.items) {
        return this.product.items.map((item) => item.product);
      }
      return [];
    },
    isInStock() {
      if (this.productGrouped.length) {
        return this.productGrouped.some(
          (product) => product.stock_status == "IN_STOCK"
        );
      }
      return this.product.stock_status == "IN_STOCK" ? true : false;
    },
    isProductInCompare() {
      return !!this.compareList.find(
        (product) => product.sku === this.product.sku
      );
    },
    btnDisable() {
      /*
      if (this.product.__typename == "ConfigurableProduct") {
        const retval = this.$store.getters["product/getCurrentChildSku"];
        if (retval != null) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
      */
      return false;
    },
    buttonClass() {
      if (this.btnDisable == true) {
        return "cursor: not-allowed;";
      } else {
        return "";
      }
    },
    finalPrice() {
      if (this.product.__typename == "ConfigurableProduct") {
        if (this.$store.getters["product/getCurrentChildSku"] != null) {
          const found = this.productConfig.variants.find((v) => {
            if (
              v.product.sku == this.$store.getters["product/getCurrentChildSku"]
            ) {
              return true;
            }
          });
          if (found != null) {
            return found.product.price_range.minimum_price.final_price.value.toFixed(
              2
            );
          }
        }
        if (
          this.product.price_range.minimum_price.final_price.value !=
          this.product.price_range.maximum_price.final_price.value
        ) {
          return (
            this.product.price_range.minimum_price.final_price.value.toFixed(
              2
            ) +
            " - " +
            this.product.price_range.maximum_price.final_price.value
              .toFixed(2)
              .replace(".", ",")
          );
        }
        return this.product.price_range.minimum_price.final_price.value.toFixed(
          2
        );
      }
      if (
        this.product.price_range.minimum_price.final_price.value !=
        this.product.price_range.maximum_price.final_price.value
      ) {
        return (
          this.product.price_range.minimum_price.final_price.value
            .toFixed(2)
            .replace(".", ",") +
          " - " +
          this.product.price_range.maximum_price.final_price.value
            .toFixed(2)
            .replace(".", ",")
        );
      }
      return this.product.price_range.minimum_price.final_price.value.toFixed(
        2
      );
    },
    regularPrice() {
      if (this.product.__typename == "ConfigurableProduct") {
        if (this.$store.getters["product/getCurrentChildSku"] != null) {
          const found = this.productConfig.variants.find((v) => {
            if (
              v.product.sku == this.$store.getters["product/getCurrentChildSku"]
            ) {
              return true;
            }
          });
          if (found != null) {
            return found.product.price_range.minimum_price.regular_price.value.toFixed(
              2
            );
          }
        }
        if (
          this.product.price_range.minimum_price.regular_price.value !=
          this.product.price_range.maximum_price.regular_price.value
        ) {
          return (
            this.product.price_range.minimum_price.regular_price.value.toFixed(
              2
            ) +
            " - " +
            this.product.price_range.maximum_price.regular_price.value
              .toFixed(2)
              .replace(".", ",")
          );
        }
        return this.product.price_range.minimum_price.regular_price.value.toFixed(
          2
        );
      }
      if (
        this.product.price_range.minimum_price.regular_price.value !=
        this.product.price_range.maximum_price.regular_price.value
      ) {
        return (
          this.product.price_range.minimum_price.regular_price.value
            .toFixed(2)
            .replace(".", ",") +
          " - " +
          this.product.price_range.maximum_price.regular_price.value
            .toFixed(2)
            .replace(".", ",")
        );
      }
      return this.product.price_range.minimum_price.regular_price.value.toFixed(
        2
      );
    },
    priceRange() {
      return this.product.price_range;
    },
    stockStatusClass() {
      return this.isInStock ? "in-stock" : "out-stock";
    },
  },

  data: () => ({
    quantity: 1,
    productsQty: {},
    totalQty: 0,
    rewardPoints: null,
    popup: false,
    addToNotifyModal: false,
    notifyModalProduct: null,
    addToCartModal: false,
    addToCompareModal: false,
  }),
  methods: {
    toggleIsInWishList() {
      this.$emit("changeIsInWishList");
    },
    formatCurrency(value) {
      var formatter = new Intl.NumberFormat("nl-NL", {
        style: "currency",
        currency: "EUR",
      });
      return formatter.format(value);
    },
    clickOk() {
      this.addToCartModal = false;
      this.$router.push("/checkout");
    },
    clickCancel() {
      this.addToCartModal = false;
      window.scrollTo(0, 0);
    },
    clickOkCompare() {
      this.addToCompareModal = false;
      this.$router.push("/compare");
    },
    clickCancelCompare() {
      this.addToCompareModal = false;
      window.scrollTo(0, 0);
    },
    async onCompareClick() {
      if (this.isProductInCompare == false) {
        await this.$store.dispatch("compare/addCompareProduct", {
          sku: this.product.sku,
        });
        this.addToCompareModal = true;
      } else {
        await this.$store.dispatch("compare/removeCompareProduct", {
          sku: this.product.sku,
        });
      }
    },
    closeNotifyModal() {
      this.notifyModalProduct = null;
      this.addToNotifyModal = false;
    },
    quantityUp(sku) {
      if (this.productsQty[sku]) {
        const updatedQty = this.productsQty[sku] + 1;
        this.productsQty = { ...this.productsQty, [sku]: updatedQty };
      } else {
        this.productsQty = { ...this.productsQty, [sku]: 1 };
      }
      this.totalQty = this.totalQty + 1;
    },
    quantityDown(sku) {
      if (this.productsQty[sku]) {
        const updatedQty = this.productsQty[sku] - 1;
        this.productsQty = { ...this.productsQty, [sku]: updatedQty };
        this.totalQty = this.totalQty - 1;
      }
    },
    addToNotify(sku) {
      const product = this.productGrouped.find((product) => product.sku == sku);
      this.notifyModalProduct = product;
      this.addToNotifyModal = true;
    },
    getGTMItems(product) {
      let items = {
        item_name: product.name,
        item_id: product.sku,
        quantity: this.quantity,
        price: this.regularPrice,
        item_brand: this.productBrand,
        discount: this.regularPrice - this.finalPrice,
      };
      return items;
    },
    async addToCart() {
      const addedProds = this.productGrouped
        .filter((product) => this.productsQty[product.sku])
        .map((product) => ({
          product: product,
          qty: this.productsQty[product.sku],
        }));

      if (addedProds.length == 0) {
        const msg = {
          type: "danger",
          title: this.$t("shopping_basket"),
          text: this.$t("bundle_product_qty"),
        };
        this.$store.dispatch("messages/sendMessage", { message: msg });
        return;
      }

      addedProds.forEach(async (element) => {
        if (element.product.__typename == "SimpleProduct") {
          const item =
            '{data: {sku: "' +
            element.product.sku +
            '", quantity:' +
            element.qty +
            "}}  ";
          let retval = await this.$store.dispatch("cart/addToCart", {
            type: element.product.__typename,
            item: item,
          });
          Logger.debug("retval", "addToCart", retval)();
          if (retval == false) {
            const msg = {
              type: "danger",
              title: this.$t("shopping_basket"),
              text: this.$t("not_add_product"),
            };
            this.$store.dispatch("messages/sendMessage", { message: msg });
          } else {
            if (this.$gtm.enabled()) {
              Logger.debug("Watch Product", "product.vue", "CALLED")();
              window.dataLayer.push({
                event: "eec.add",
                ecommerce: {
                  add: {
                    currency: "EUR",
                    value: this.finalPrice,
                    products: [
                      {
                        name: this.product.name,
                        id: this.product.sku,
                        quantity: this.quantity,
                      },
                    ],
                  },
                },
              });
              if (this.$gtm.ga4Enabled) {
                window.dataLayer.push({
                  event: "add_to_cart",
                  ecommerce: {
                    add: {
                      currency: "EUR",
                      value: this.finalPrice,
                      items: [this.getGTMItems(element)],
                    },
                  },
                });
              }
            }
            this.addToCartModal = true;
            setTimeout(() => {
              this.addToCartModal = false;
            }, 5000);
            // this.$bvModal.show(`modal${this.product.sku}`);
          }
        }
      });
    },
    getNamePart2(product) {
      Logger.debug("getNamePart2", "Grouped", product.name.split("|"))();
      var part2 = "|" + product.name.split("|")[1];
      if (part2 !== "|undefined") {
        return part2;
      }
      return "";
    },
  },
};
</script>
