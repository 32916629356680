<template>
  <div class="alert-block">
    <b-alert
      v-model="showDismissibleAlert"
      class="d-flex align-items-center"
      :variant="product.alertVariant || 'success'"
      dismissible
    >
      <div v-if="isInfoAlert()" class="alert-content">
        <i class="fas fa-bell"></i>
        <span>Let op! probeer het nog eens</span>
      </div>
      <div v-if="isDangerAlert()" class="alert-content">
        <i class="fas fa-times-circle"></i>
        <span>Mislukt, probeer het nog eens</span>
      </div>
      <div v-if="isWarningAlert()" class="alert-content">
        <i class="fas fa-exclamation-triangle"></i>
        <span>Waarschuwing</span>
      </div>
      <div
        v-if="
          isSuccessAlert() ||
          (!isWarningAlert() && !isInfoAlert() && !isDangerAlert())
        "
        class="d-flex justify-content-between align-items-center alert-content"
      >
        <ClientOnly>
          <div class="left-content">
            <i class="fas fa-check-circle"></i>
            <span>{{ product.name }}</span>
            <span> aan je</span>
            <b-link href="#" class="content-link"> verlanglijstje.</b-link>
          </div>
        </ClientOnly>
        <b-link href="#" class="btn alert-btn ml-20">Wensenlijst</b-link>
      </div>
    </b-alert>
  </div>
</template>

<script>
import ClientOnly from "vue-client-only";
export default {
  name: "ProductAlert",
  props: ["product", "showDismissibleAlert"],
  components: {
    ClientOnly
  },
  data() {
    return {};
  },
  methods: {
    isInfoAlert() {
      return this.product.alertVariant === "info";
    },
    isDangerAlert() {
      return this.product.alertVariant === "danger";
    },
    isWarningAlert() {
      return this.product.alertVariant === "warning";
    },
    isSuccessAlert() {
      return this.product.alertVariant === "success";
    },
  },
};
</script>
