<template>
  <div class="product-description">
    <div class="custom-nav-tabs">
      <ul class="nav nav-tabs">
        <li class="nav-item" v-if="product.description && product.description.html">
          <b-link class="nav-link" :class="{ active: activeTab === 'product-details' }"
            @click="scrollToTab($event, 'product-details')">{{ $t("product_description") }}
            <span class="mob-icon"><i class="fa fa-chevron-right"></i></span></b-link>
        </li>
        <li class="nav-item" v-if="product_specifications.length">
          <b-link class="nav-link" :class="{ active: activeTab === 'product-specifications' }"
            @click="scrollToTab($event, 'product-specifications')">{{ $t("product_specifications") }}
            <span class="mob-icon"><i class="fa fa-chevron-right"></i></span></b-link>
        </li>
        <li class="nav-item">
          <b-link class="nav-link" :class="{ active: activeTab === 'product-reviews' }"
            @click="scrollToTab($event, 'product-reviews')">{{ $t("review") + `(${product.review_count})`
            }}<span class="mob-icon"><i class="fa fa-chevron-right"></i></span>
          </b-link>
        </li>
      </ul>
    </div>

    <div class="product-description-tab">
      <b-row>
        <b-col col-lg-12>
          <h4 class="specifications description-title product-page-title">
            {{ $t("product_description") }}
          </h4>
          <div v-html="product.description.html" id="product-details" class="product-description-text"></div>
        </b-col>
      </b-row>

      <div id="product-specifications" class="product-specifications">
        <h4 class="specifications product-page-title">
          {{ $t("product_specifications") }}
        </h4>
        <div class="product-specification-list" v-if="product_specifications.length">
          <div class="d-flex mb-2" v-for="(info, index) in product_specifications" :key="index">
            <div v-if="info.value != 'false'" class="category d-flex">
              <div class="spec-tooltip mr-3 cursor-pointer" @click="info.tooltip != null && (activeTooltipId = index)">
                <div :class="{ disabled: !info.tooltip }">
                  <i class="fas fa-info-circle"></i>
                </div>
              </div>
              <b-modal :id="'modal' + index" :visible="activeTooltipId === index" @hidden="activeTooltipId = null"
                hide-header hide-footer centered>
                <div class="relative">
                  <div class="close-modal" @click="activeTooltipId = null">
                    <span><i class="fa fa-times"></i></span>
                  </div>
                  <div class="spectmodal-content fancy-scroll">
                    <div v-html="info.tooltip"></div>
                  </div>
                </div>
              </b-modal>
              <span>{{ info.label }}</span>
            </div>
            <div v-if="info.value != 'false'" class="detail ml-20 d-block">
              <span>{{ info.value }}</span>
            </div>
          </div>
          <div v-if="product_specifications.length < informationAttr.length">
            <b-link class="show-more" @click="showMoreSpecifications">
              {{ $t("show_more") }}
            </b-link>
          </div>
        </div>
      </div>

      <!-- Review highlights - typically goes below the product description or image -->
      <div id="product-reviews" class="product-review">
        <div data-bv-show="review_highlights" :data-bv-product-id="product.sku"></div>

        <!-- Reviews for productID123 here -->
        <div data-bv-show="reviews" :data-bv-product-id="product.sku"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { isMobileOnly } from 'mobile-device-detect';

export default {
  name: "DescriptionTab",
  computed: {
    ...mapGetters({
      product: "product/getCurrentProduct",
      related: "product/getRelated",
    }),
    mobileCheck() {
      if (isMobileOnly) {
        return true;
      } else {
        return false;
      }
    },
    informationAttr() {
      return this.product.information_attributes.filter(
        (item) =>
          !item.value?.startsWith("X") &&
          !item.value?.startsWith("xx") &&
          !item.value?.startsWith("x,xx")
      );
    },
  },
  created() {
    if (this.product) {
      this.product_specifications = this.informationAttr.slice(0, 10);
    }
  },

  data: () => ({
    activeTab: "product-details",
    product_specifications: [],
    activeTooltipId: null,
    description: {
      details:
        "De Trani is een winterjack van Rogelli die speciaal is ontworpen voor de winter. Inmiddels al aan de sterk verbeterde versie 3.0. Het jack is winddicht en waterafstotend zodat je optimaal kunt presteren in koude weersomstandigheden. Voor optimaal comfort zijn er softshell stukken aan de voorkant en isolerend fleece aan de binnenvoering aangebracht. Aan de achterzijde zijn 3 zakjes.",
      features: [
        "Winddicht en waterafstotend",
        " Race pasvorm",
        " Warmte isolerend fleece binnenvoering",
        " Elastische band aan de pols",
        " Softshell stukken op de voorkant",
        " Silicone antislipstrip in de taille",
        " Extra rug- en borstzak met ritssluiting",
        "D.T. PENSTAR stof",
        "Lange rits",
        "Reflecterende accenten voor extra zichtbaarheid",
      ],
      moreInfo: [
        { category: "Productgroep", info: "Fietsjack winter" },
        { category: "Introductie jaar", info: "2018" },
        { category: "Gebruikte materialen", info: "100% polyester" },
        {
          category: "Temperatuuradvies",
          info: "Vanaf temperaturen onder het vriespunt",
        },
      ],
    },
    reviews: {
      noOfReviews: 15,
    },
  }),

  methods: {
    scrollToTab(_, elementId) {
      this.activeTab = elementId;

      const element = document.getElementById(elementId);
      const offset = 250;
      const bodyRect = document.body.getBoundingClientRect().top;
      const elementRect = element.getBoundingClientRect().top;
      const elementPosition = elementRect - bodyRect;
      const offsetPosition = elementPosition - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    },
    showMoreSpecifications() {
      this.product_specifications = this.informationAttr;
    },
  },
};
</script>
