<template>
  <div class="ratings">
    <label class="d-block required-value">{{ reviewData.name }}:</label>
    <div class="stars">
      <ul class="ratings-star">
        <li
          v-for="(isFill, idx) of rateStars"
          :key="idx"
          :class="isFill ? 'text-yellow' : 'text-gray'"
        >
          <div @click="clickStar(idx)">
            <ClientOnly>
              <!-- <i class="fa fa-star"></i> -->
              <b-icon icon="star-fill" v-if="isFill"></b-icon>
              <b-icon icon="star" v-else></b-icon>
            </ClientOnly>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import ClientOnly from "vue-client-only";
export default {
  name: "ReviewRating",
  components: {
    ClientOnly,
  },
  props: {
    value: {
      type: String,
    },
    reviewData: {
      type: Object,
    },
  },
  computed: {
    rateStars() {
      const found = this.reviewData.values.find(
        (v) => v.value_id === this.value
      );
      return new Array(5)
        .fill(false)
        .map((_, i) => found && i < parseInt(found.value));
    },
  },
  methods: {
    clickStar(idx) {
      const found = this.reviewData.values.find((v) => {
        if (parseInt(v.value) == idx + 1) {
          return true;
        }
      });
      if (found != null) {
        this.$emit("input", found.value_id);
      }
    },
  },
};
</script>