<template>
  <div class="ratings">
    <label>{{ title }}</label>
    <ul class="ratings-star">
      <li
        v-for="(isFill, idx) of rateStars"
        :key="idx"
        :class="isFill ? 'text-yellow' : 'text-gray'"
      >
        <ClientOnly>
          <!-- <i class="fa fa-star"></i> -->
          <b-icon icon="star-fill" v-if="isFill"></b-icon>
          <b-icon icon="star" v-else></b-icon>
        </ClientOnly>
      </li>
    </ul>
  </div>
</template>

<script>
import ClientOnly from "vue-client-only";
export default {
  name: "ProductRating",
  components: {
    ClientOnly,
  },
  props: {
    stars: {
      type: Number,
    },
    title: {
      type: String,
    },
  },
  computed: {
    rateStars() {
      return new Array(5).fill(false).map((_, i) => i < this.stars);
    },
  },
  data() {
    return {};
  },
};
</script>

