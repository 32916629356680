<template>
  <div class="ProductReview">
    <b-modal
      id="modal-review"
      okVariant="success"
      okTitle="Ok"
      :title="$t('review_send')"
      @ok="clickReviewOk"
    >
      <p class="my-4">{{ $t("review_thankyou") }}</p>
    </b-modal>

    <b-modal
      id="modal-more-review"
      okTitle="Ok2"
      :title="`Review(s) (${product.review_count})`"
      @ok="clickReviewOk"
      :hide-footer="true"
      size="lg"
      dialog-class="all-review-modal"
    >
      <div class="review-box-wrap">
        <div
          class="review-box"
          v-for="(rev, index) of product.reviews.items"
          :key="index"
        >
          <div v-for="(rat, idx) of rev.ratings_breakdown" :key="idx">
            <ProductRating :title="rat.name" :stars="rat.value"></ProductRating>
          </div>
          <!-- <div class="nickname">{{ rev.nickname }}</div> -->
          <!-- <div class="summary">{{ rev.summary }}</div> -->
          <div class="summary">{{ getReviewDate(rev.created_at) }}</div>
          <div class="review">"{{ rev.text }}"</div>
        </div>
      </div>
    </b-modal>

    <h3 class="semiBold-18">{{ $t("productreview") }}</h3>
    <div class="regular-12" v-if="product.reviews.items.length == 0">
      {{ $t("no_reviews") }}
    </div>
    <div class="review-block">
      <div class="row">
        <div class="col-md-12 col">
          <div class="review-wrap" v-if="product != null">
            <div class="review-inner">
              <div
                class="review-box"
                v-for="(rev, index) of product.reviews.items.slice(0, 4)"
                :key="index"
              >
                <div v-for="(rat, idx) of rev.ratings_breakdown" :key="idx">
                  <ProductRating
                    :title="rat.name"
                    :stars="rat.value"
                  ></ProductRating>
                </div>
                <!-- <div class="nickname">{{ rev.nickname }}</div> -->
                <!-- <div class="summary">{{ rev.summary }}</div> -->

                <div class="summary">{{ getReviewDate(rev.created_at) }}</div>

                <div class="review">{{ rev.text }}</div>
              </div>
              <a
                v-if="product.review_count > 4"
                @click="openReviewsPopUp"
                class="more-review-btn"
                >{{ $t("show_more_reviews") }}</a
              >
            </div>
          </div>
          <div class="product-review-form">
            <h4 class="semiBold-15">{{ $t("write_review") }}</h4>
            <div class="product-review-form-wrap">
              <div class="product-review-form-inner">
                <div class="product-review-form-box">
                  <div v-for="(ritem, idx) of reviewMeta" :key="idx">
                    <ReviewRating
                      :reviewData="ritem"
                      v-model="ratings[idx]"
                    ></ReviewRating>
                  </div>
                  <div class="invalid-rating" v-html="rating_error" />
                  <div
                    id="Nickname"
                    role="group"
                    class="form-group review-inputs"
                  >
                    <label
                      id="first-Nickname"
                      for="first_name"
                      class="d-block required-value"
                      >{{ $t("nickname") }}</label
                    >
                    <div class="no-focus-ring">
                      <b-form-input
                        id="first_name"
                        type="text"
                        class="form-control"
                        aria-describedby="firstnickname-Review"
                        aria-required="true"
                        required
                        v-model="nickname"
                        :state="nickname_state"
                      />
                      <div
                        id="firstnickname-review"
                        class="invalid-nickname"
                        v-html="nickname_error"
                      ></div>
                    </div>
                  </div>
                  <div
                    id="Summary"
                    role="group"
                    class="form-group review-inputs"
                  >
                    <label
                      id="Summary"
                      for="Summary"
                      class="d-block required-value"
                      >{{ $t("review_email") }}</label
                    >
                    <div class="no-focus-ring">
                      <b-form-input
                        id="Summary"
                        type="email"
                        class="form-control"
                        aria-required="true"
                        required
                        aria-describedby="Summary-Review"
                        v-model="summary"
                        :state="summary_state"
                      />
                      <div
                        id="Summary-review"
                        class="invalid-Summary"
                        v-html="summary_error"
                      ></div>
                    </div>
                  </div>
                </div>
                <div class="product-review-form-box">
                  <div
                    id="Review"
                    role="group"
                    class="form-group review-inputs"
                  >
                    <label
                      id="Review"
                      for="Review"
                      class="d-block required-value"
                      >{{ $t("experience_text") }}</label
                    >
                    <div class="no-focus-ring">
                      <b-form-textarea
                        name="Review"
                        v-model="text"
                        aria-required="true"
                        required
                        :state="text_state"
                      ></b-form-textarea>
                      <div
                        id="review"
                        class="invalid-Review"
                        v-html="text_error"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>

              <button
                @click="sendReview"
                class="c-btn c-btn-primary reviewbutton"
              >
                {{ $t("submitreview") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ProductRating from "./ProductRating.vue";
import ReviewRating from "./ReviewRating.vue";
import { createProductReview } from "@storefront/core/data-resolver/products";
import { Logger } from "@storefront/core/lib/logger";
// import VueSlickCarousel from "vue-slick-carousel";

export default {
  name: "ProductReview",
  components: {
    ProductRating,
    ReviewRating,
    // VueSlickCarousel,
  },
  beforeCreate() {},
  data() {
    return {
      test: null,
      nickname: "",
      nickname_error: "",
      nickname_state: null,
      summary: "",
      summary_error: "",
      summary_state: null,
      text: "",
      text_error: "",
      text_state: null,
      ratings: [],
      rating_error: "",
      carouselConf: {
        dots: false,
        infinite: true,
        arrows: true,
        speed: 1300,
        slidesToShow: 2,
        slidesToScroll: 1,

        responsive: [
          {
            breakpoint: 1030,
            settings: {
              autoplay: true,
              fade: true,
              slidesToShow: 3,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 600,
            settings: {
              autoplay: true,
              fade: true,
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              speed: 2500,
              autoplay: true,
              autoplaySpeed: 0,
              cssEase: "linear",
              slidesToShow: 1,
              infinite: true,
            },
          },
        ],
      },
    };
  },

  methods: {
    getReviewDate(dateStr) {
      if (!dateStr) {
        return "";
      }

      const monthStr = new Date(dateStr).toLocaleString("default", {
        month: "short",
      });
      const formateDate = dateStr.split(" ")[0].split("-").reverse().join("-");

      return `reviewed by ${monthStr} on ${formateDate}`;
    },

    openReviewsPopUp() {
      this.$bvModal.show("modal-more-review");
    },
    async sendReview() {
      const ratings = [];
      this.reviewMeta.forEach((element, index) => {
        Logger.debug("sendReview", "ProductReview", [element, index])();
        if (typeof this.ratings[index] != "undefined") {
          let obj = {
            id: element.id,
            value_id: this.ratings[index],
            name: element.name,
          };
          ratings.push(obj);
        } else {
          //alert("AAAHHHHH");
        }
      });
      Logger.debug("sendForm", "ProductReview", ratings)();

      const inp = {};
      let formValid = true;
      const validEmail =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
      if (this.nickname == "") {
        formValid = false;
        this.nickname_error = this.$t("dyform_required");
        this.nickname_state = false;
      } else {
        this.nickname_error = "";
        this.nickname_state = true;
      }
      if (this.summary == "" || validEmail.test(this.summary) !== true) {
        formValid = false;
        this.summary_error = this.$t("dyform_emailaddress");
        this.summary_state = false;
      } else {
        this.summary_error = "";
        this.summary_state = true;
      }
      if (this.text == "" || this.text.length < 20) {
        formValid = false;
        this.text_error = this.$t("dyform_min_text_length", {
          aantal: parseInt(20),
        });
        this.text_state = false;
      } else {
        this.text_error = "";
        this.text_state = true;
      }
      if (ratings.length == 0) {
        formValid = false;
        this.rating_error = this.$t("rating_required");
      } else if (ratings.length == 1) {
        formValid = false;
        this.rating_error = this.$t(
          "rating_" + ratings[0].name.toLowerCase() + "_required"
        );
      } else {
        this.rating_error = "";
      }
      inp.sku = this.product.sku;
      inp.nickname = this.nickname;
      inp.summary = this.summary;
      inp.text = this.text;
      inp.ratings = ratings;
      this.test = inp;
      Logger.debug("inp", "ProductReview", inp)();

      let retval = false;
      if (formValid) {
        retval = await createProductReview(inp);
      }
      Logger.debug("retval", "ProductReview", retval)();
      if (retval != false) {
        this.$bvModal.show("modal-review");
        this.nickname = "";
        this.nickname_error = "";
        this.nickname_state = null;
        this.summary = "";
        this.summary_error = "";
        this.summary_state = null;
        this.text = "";
        this.text_error = "";
        this.text_state = null;
        // this.ratings.forEach((element, index) => {
        //   element = null;
        //   this.ratings[index] = null;
        // });
        this.ratings = [];
        this.rating_error = "";
      }
    },
    clickReviewOk() {
      this.$bvModal.hide("modal-review");
    },
  },
  computed: {
    ...mapGetters({
      product: "product/getCurrentProduct",
      reviewMeta: "product/getProductReviewRatingsMetadata",
    }),
  },
};
</script>
