<template>
    <div class="label-overlay" v-if="productlabel">
      <div :class="CalcLabelPosition(productlabel.position)">
        <span :style="parseStyle(productlabel.css)">{{ productlabel.text }}</span>
      </div>
  </div>
  </template>
  
  <script>
  
  export default {
    name: "ProductLabel",
    props: {
      productlabel: {
        type: Object,
      },
    },
    methods: {
        parseStyle(styleString) {
        let style = styleString.split(';').slice(1).join(';');
        console.log(style);
        return style;

    },
    CalcLabelPosition(position) {
        return "label-overlay-text " + position;
    }
  }
}
  </script>
  
  <style lang="scss" scoped>
  </style>
  