<template>
  <div>
    <b-tabs
      active-nav-item-class="active-opener"
      class="product--description-tab isTab"
    >
      <b-tab :title="$t('product_description')" active>
        <div
          class="product_description-inner"
          v-html="product.description.html"
        ></div>
      </b-tab>
      <b-tab :title="$t('product_specifications')">
        <template v-for="(info, index) in product.information_attributes">
          <div
            class="single-specification"
            v-if="info.value != 'false'"
            :key="index"
          >
            <span class="category d-block">{{ info.label }}</span>
            <span class="detail ml-20 d-block">{{ info.value }}</span>
          </div>
        </template>
      </b-tab>
      <b-tab
        :title="$t('req_information')"
        @click="scroll('product-form')"
      ></b-tab>
      <b-tab
        title="Downloads"
        class="download-tab"
        v-if="getDownloadCounts > 0"
      >
        <div class="download-wrap">
          <div
            class="download-single"
            v-for="(item, idx) in product.downloads"
            :key="idx"
          >
            <div class="download-box">
              <div class="download-document">
                <a :href="item.url" target="_blank">
                  <img
                    class="download-img"
                    :src="getFileIcon(item.type.toLowerCase())"
                    :alt="`${item.type.toLowerCase()}`"
                  />

                    {{
                      $t("Product information about {product_name}", {
                        product_name: productFullName,
                      })
                    }}
                  ></a
                >
                {{ $t("file_size") }}: {{ item.size }}
              </div>
            </div>
          </div>
        </div>
      </b-tab>
      <b-tab
        :title="$t('product_review') + `(${product.review_count})`"
        @click="scroll('product-review')"
      >
      </b-tab>
    </b-tabs>

    <template>
      <div class="product-detail-mobile accordion isMobile" role="tablist">
        <div class="pdm-box" v-b-toggle="'productDisc'">
          {{ $t("product_description") }}
        </div>

        <b-collapse id="productDisc" role="tabpanel">
          <div v-html="product.description.html"></div>
        </b-collapse>

        <div
          class="pdm-box"
          v-b-toggle="'specifcaties'"
          v-if="
            product.information_attributes &&
            product.information_attributes.length
          "
        >
          {{ $t("product_specifications") }}
        </div>

        <b-collapse id="specifcaties" role="tabpanel">
          <template v-for="(info, index) in product.information_attributes">
            <div
              class="single-specification"
              v-if="info.value != 'false'"
              :key="index"
            >
              <span class="category d-block">{{ info.label }}</span>
              <span class="detail ml-20 d-block">{{ info.value }}</span>
            </div>
          </template>
        </b-collapse>

        <div class="pdm-box" @click="scroll('product-form')">
          {{ $t("req_information") }}
        </div>

        <div class="pdm-box" v-b-toggle="'download'">Downloads</div>

        <b-collapse id="download" role="tabpanel" class="download-tab">
          <div class="download-wrap">
            <div
              class="download-single"
              v-for="(item, idx) in product.downloads"
              :key="idx"
            >
              <div class="download-box">
                <div class="download-document">
                  <a :href="item.url"
                    ><img
                      class="download-img"
                      :src="getFileIcon(item.type.toLowerCase())"
                      alt="`${item.type.toLowerCase()}`"

                   />
                    <strong>
                      {{
                        $t("Product information about {product_name}", {
                          product_name: productFullName,
                        })
                      }}
                    </strong>
                    {{ $t("file_size") }}: {{ item.size }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </b-collapse>

        <div class="pdm-box" @click="scroll('product-review')">
          {{ $t("product_review") }} {{ `(${product.review_count})` }}
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { isMobile, isTablet } from "mobile-device-detect";

import icondefault from '@/base/assets/imgs/files/default.png'
import iconcsv from '@/base/assets/imgs/files/csv.png'
import icondoc from '@/base/assets/imgs/files/doc.png'
import icondocx from '@/base/assets/imgs/files/docx.png'
import icongif from '@/base/assets/imgs/files/gif.png'
import iconjpeg from '@/base/assets/imgs/files/jpeg.png'
import iconjpg from '@/base/assets/imgs/files/jpg.png'
import iconpdf from '@/base/assets/imgs/files/pdf.png'
import iconpng from '@/base/assets/imgs/files/png.png'
import icontxt from '@/base/assets/imgs/files/txt.png'
import iconxls from '@/base/assets/imgs/files/xls.png'
import iconxlsx from '@/base/assets/imgs/files/xlsx.png'
import iconxml from '@/base/assets/imgs/files/xml.png'

//import VRuntimeTemplate from "v-runtime-template";
// import ProductReview from "./ProductReview.vue";
export default {
  icondefault, icondocx,iconcsv,icondoc,iconpdf,iconxls,iconxlsx,icongif,iconjpeg,iconjpg,iconpng,icontxt,iconxml,
  name: "DescriptionTab",
  components: {

    //VRuntimeTemplate
    // ProductReview,
  },
  computed: {
    ...mapGetters({
      product: "product/getCurrentProduct",
    }),
    isMobileScreen() {
      return isMobile;
    },
    isTabShow() {
      return isTablet;
    },
    getDownloadCounts() {
      return this.product.downloads.length;
    },

    productFullName() {
      let name = "";
      if (this.productBrand != "") name += this.productBrand + " ";
      if (this.productModel != "") name += this.productModel + " ";
      name += this.product.name;
      return name;
    },
    productBrand() {
      let productBrand = "";
      if (this.product.information_attributes) {
        const brand = this.product.information_attributes.find(
          (item) => item.attribute_code == "merk"
        );
        if (brand) {
          productBrand = brand.value;
        }
      }
      return productBrand;
    },
    productModel() {
      let productModel = "";
      if (this.product.information_attributes) {
        const model = this.product.information_attributes.find(
          (item) => item.attribute_code == "model_number"
        );
        if (model) {
          productModel = model.value;
        }
      }
      return productModel;
    },
  },
  async created() {

  },
  data: () => (
      {
      description: {
      details:
        "De Trani is een winterjack van Rogelli die speciaal is ontworpen voor de winter. Inmiddels al aan de sterk verbeterde versie 3.0. Het jack is winddicht en waterafstotend zodat je optimaal kunt presteren in koude weersomstandigheden. Voor optimaal comfort zijn er softshell stukken aan de voorkant en isolerend fleece aan de binnenvoering aangebracht. Aan de achterzijde zijn 3 zakjes.",
      features: [
        "Winddicht en waterafstotend",
        " Race pasvorm",
        " Warmte isolerend fleece binnenvoering",
        " Elastische band aan de pols",
        " Softshell stukken op de voorkant",
        " Silicone antislipstrip in de taille",
        " Extra rug- en borstzak met ritssluiting",
        "D.T. PENSTAR stof",
        "Lange rits",
        "Reflecterende accenten voor extra zichtbaarheid",
      ],
      moreInfo: [
        { category: "Productgroep", info: "Fietsjack winter" },
        { category: "Introductie jaar", info: "2018" },
        { category: "Gebruikte materialen", info: "100% polyester" },
        {
          category: "Temperatuuradvies",
          info: "Vanaf temperaturen onder het vriespunt",
        },
      ],
    },
    reviews: {
      noOfReviews: 15,
    },
  }),
  methods: {
    getFileIcon(filetype){
      switch (filetype) {
        case 'csv':
          return iconcsv
        case 'doc':
          return icondoc
        case 'docx':
          return icondocx
        case 'gif':
          return icongif;
        case 'jpg':
          return iconjpeg;
        case 'jpeg':
          return iconjpg;
        case 'pdf':
          return iconpdf;
        case 'png':
          return iconpng;
        case 'txt':
          return icontxt;
        case 'xls':
          return iconxls;
        case 'xlsx':
          return iconxlsx;
        case 'xml':
          return iconxml;
        default:
          return icondefault;
      }
    },
    scroll(elId) {
      document.getElementById(elId).scrollIntoView({
        behavior: "smooth",
      });
    },
  },
};
</script>
