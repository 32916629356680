<template>
  <div>
    <div class="ratings">
      <ul class="ratings-star">
        <li
          v-for="(isFill, idx) of rateStars"
          :key="idx"
          :class="isFill ? 'text-yellow' : 'text-gray'"
        >
          <ClientOnly>
          <!-- <i class="fas fa-star"></i> -->
          <b-icon icon="star-fill" v-if="isFill"></b-icon>
              <b-icon icon="star" v-else></b-icon>
          </ClientOnly>
        </li>
      </ul>
      <span class="no-of-ratings">({{ noOfRatings }})</span>
    </div>
  </div>
</template>

<script>
import ClientOnly from "vue-client-only";
export default {
  name: "Rating",
  components: {
    ClientOnly
  },
  props: {
    stars: {
      type: Number,
    },
    noOfRatings: {
      type: Number,
    },
  },
  computed: {
    rateStars() {
      return new Array(5).fill(false).map((_, i) => i < this.stars / 10 / 2);
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.ratings {
  padding: 5px 0;
  line-height: 1;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
}

.text-yellow {
  color: green;
}

.text-gray {
  color: #cdcdcd;
}

.ratings-star {
  list-style: none;
  padding: 0;
  display: flex;
  margin: 0 -4px;

  li {
    margin: 0 1px;
  }
}

.no-of-ratings {
  font-weight: 300;
  font-size: 15px;
  margin-left: 9px;
}

@media screen and (max-width:670px) {
  .no-of-ratings{
    font-size: 10px;
    font-weight: 600;
  }
}
</style>
