<template>
  <div class="product-gallery">
    <div class="selected-image-holder" @click.prevent="showMultiple">
      <div class="actionbuttons ttttes">
        <span class="sale-txt" v-if="onSale == true">Aanbieding</span>
        <div class="new-txt" v-if="onNew == true">Nieuw</div>
      </div>

      <div
        class="selected-image"
        :style="{ backgroundImage: `url(${selectedImage})` }"
      ></div>
    </div>
    <div class="product-view-btn">
      <div @click.prevent="showMultiple">
        <i class="fas fa-search-plus"></i>
      </div>
    </div>

    <ClientOnly>
      <Lightbox
        moveDisabled
        :visible="visible"
        :imgs="imgs"
        :index="index"
        @hide="handleHide"
      >
      </Lightbox>
      <Carousel
        class="carousel"
        :autoplay="false"
        :loop="true"
        :navigationEnabled="true"
        :perPage="noOfSlidePerPage"
        :scrollPerPage="false"
        :paginationEnabled="false"
        navigationPrevLabel="<i class='fas fa-chevron-left'></i>"
        navigationNextLabel="<i class='fas fa-chevron-right'></i>"
      >
        <Slide v-for="(image, i) of images" :key="`${i}`" :id="`slide ${i}`">
          <b-link href="#" class="slide-img-holder" @click="clickSlideGallery">
            <div
              class="slide-img"
              :style="{ backgroundImage: `url(${image.url})` }"
            ></div>
          </b-link>
        </Slide>
      </Carousel>
    </ClientOnly>
  </div>
</template>

<script>
import { isServer } from "@storefront/core/helpers";
import ClientOnly from "vue-client-only";
import { mapGetters } from "vuex";

import { Logger } from "@storefront/core/lib/logger";

export default {
  name: "ProductGallery",
  props: ["images", "isInWishList"],
  components: {
    ClientOnly,
    Carousel: () =>
      !isServer ? import("vue-carousel").then((m) => m.Carousel) : null,
    Slide: () =>
      !isServer ? import("vue-carousel").then((m) => m.Slide) : null,
    Lightbox: () => (!isServer ? import("vue-easy-lightbox") : null),
  },
  data() {
    return {
      selectedImage: this.images[0].url,
      noOfSlidePerPage: 1,
      imgs: "",
      visible: false,
      index: 0,
      slideNumber: 0,
    };
  },
  computed: {
    ...mapGetters({
      product: "product/getCurrentProduct",
    }),
    onSale() {
      return this.product.sale;
    },
    onNew() {
      return this.product.new;
    },
  },
  watch: {
    images: function (oldVal, newVal) {
      Logger.debug("newval[0]", "watch", newVal[0].url)();
      Logger.debug("this.selectedImage", "watch", this.selectedImage)();

      this.selectedImage = newVal[0].url;

      Logger.debug("this.selectedImage", "watch", this.selectedImage)();

      Logger.debug("oldVal", "watch", oldVal[0].url)();
      Logger.debug("newVal", "watch", newVal[0].url)();
      this.selectedImage = oldVal[0].url;
    },
  },
  mounted() {
    window.addEventListener("resize", this.setNoOfSlidePerPage);
    window.addEventListener("load", this.setNoOfSlidePerPage);
    this.setNoOfSlidePerPage();
  },
  destroyed() {
    Logger.debug("destroyed", "ProductGallery", "destroyed is called")();
    window.removeEventListener("resize", this.setNoOfSlidePerPage);
    window.removeEventListener("load", this.setNoOfSlidePerPage);
  },
  methods: {
    clickSlideGallery(e) {
      this.changeSelectedImage(e);
      // if (window.innerWidth < 768) {
      //   this.showMultiple();
      // }
    },
    changeSelectedImage(e) {
      const slideNumber = e.target.parentElement.id.replace(/^\D+/g, "");
      this.slideNumber = +slideNumber;
      this.selectedImage = this.images[slideNumber].url;
    },
    showMultiple() {
      this.imgs = this.images.map(image=>image.url);
      this.index = this.slideNumber;
      this.show();
    },
    show() {
      this.visible = true;
    },
    handleHide() {
      this.visible = false;
    },
    setNoOfSlidePerPage() {
      this.noOfSlidePerPage = window.innerWidth < 768 ? 2 : 3;
    },
    toggleIsInWishList() {
      this.$emit("changeIsInWishList");
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
