<template>
  <div class="product-gallery">
    <NotifyModal :show="showNotifyModal" :product-sku="product.sku" @close="closeNotifyModal" />

    <b-link @click="toggleWishItem" class="add-to-wishlist" v-b-tooltip.hover.right="'Toevoegen aan wensenlijst'">
      <div v-if="$store.getters['wishlist/isProductInWishlist'](product.sku)">
        <b-icon icon="heart-fill" class="added"></b-icon>
      </div>
      <b-icon icon="heart-fill" class="not-added" v-else></b-icon>
    </b-link>

    <b-row>
      <b-col col lg="10" md="10" cols="12">
        <ProductLabel v-for="(label, index) in product.labels" :key="index" :productlabel="label.product"></ProductLabel>
        <div class="big-img">
          <div class="selected-image-holder d-none d-md-block" @click.prevent="showMultiple">
            <div class="selected-image" :style="{ backgroundImage: `url(${selectedImage})` }"></div>
          </div>
        </div>
      </b-col>
      <b-col col lg="2" md="2" cols="12">
        <div>
          <ClientOnly>
            <Lightbox :visible="visible" :imgs="lightBoxImages" :index="ligthBoxIndex" @hide="handleHide"></Lightbox>
            <VueSlickCarousel v-bind="settings" class="carousel" :style="{ height: '372px' }">
              <div v-for="(image, idx) of images" :key="idx" :id="`slide ${idx}`">
                <b-link class="slide-img-holder" @click="clickSlideGallery(idx)">
                  <div class="slide-img" :style="{ backgroundImage: `url(${image.url})` }"></div>
                </b-link>
              </div>
            </VueSlickCarousel>
          </ClientOnly>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
//import { Carousel, Slide } from "vue-carousel";
import { isServer } from "@storefront/core/helpers";
import ClientOnly from "vue-client-only";
//import Lightbox from "vue-easy-lightbox";
import { Logger } from "@storefront/core/lib/logger";
import NotifyModal from "@/base/core/components/common/NotifyModal.vue";
import ProductLabel from "@/esf_antarctica_lid/core/components/category-product/ProductLabel.vue";

export default {
  name: "ProductGallery",
  props: ["product", "images", "isInWishList"],
  components: {
    ClientOnly,
    // Carousel: () =>
    //   !isServer ? import("vue-carousel").then((m) => m.Carousel) : null,
    // Slide: () =>
    //   !isServer ? import("vue-carousel").then((m) => m.Slide) : null,
    VueSlickCarousel: () => (!isServer ? import("vue-slick-carousel") : null),
    Lightbox: () => (!isServer ? import("vue-easy-lightbox") : null),
    NotifyModal,
    ProductLabel
  },
  data() {
    return {
      selectedImage: this.images[0].url,
      showNotifyModal: false,
      noOfSlidePerPage: 1,
      imgs: "",
      visible: false,
      index: 0,
      ligthBoxIndex: 0,
      slideNumber: 0,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
        vertical: true,
        arrows: true,
        verticalSwiping: true,
        focusOnSelect: true,
        responsive: [
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1,
              verticalSwiping: false,
              vertical: false,
            },
          },
        ],
      },
    };
  },
  computed: {
    lightBoxImages() {
      return this.images.map((image) => image.url);
    },
  },
  watch: {
    images: function (oldVal, newVal) {
      Logger.debug("newval[0]", "watch", newVal[0].url)();
      Logger.debug("this.selectedImage", "watch", this.selectedImage)();

      this.selectedImage = newVal[0].url;

      Logger.debug("this.selectedImage", "watch", this.selectedImage)();

      Logger.debug("oldVal", "watch", oldVal[0].url)();
      Logger.debug("newVal", "watch", newVal[0].url)();
      this.selectedImage = oldVal[0].url;
    },
  },
  mounted() {
    window.addEventListener("resize", this.setNoOfSlidePerPage);
    window.addEventListener("load", this.setNoOfSlidePerPage);
    this.setNoOfSlidePerPage();
    // this.sortImages();
  },
  destroyed() {
    Logger.debug("destroyed", "ProductGallery", "destroyed is called")();
    window.removeEventListener("resize", this.setNoOfSlidePerPage);
    window.removeEventListener("load", this.setNoOfSlidePerPage);
  },
  methods: {
    /*  sortImages(){
        console.log(this.images);
        return this.images.slice().sort(function(a, b) {
          return a.position - b.position;
        })
        console.log(this.images);

      },*/

    // clickSlideGallery(e) {
    //   this.changeSelectedImage(e);
    //   if (window.innerWidth < 768) {
    //     this.showMultiple();
    //   }
    // },
    // changeSelectedImage(e) {
    //   const slideNumber = e.target.parentElement.id.replace(/^\D+/g, "");
    //   this.slideNumber = +slideNumber;
    //   this.selectedImage = this.images[slideNumber].url;
    // },
    clickSlideGallery(id) {
      this.changeSelectedImage(id);
      if (window.innerWidth < 768) {
        this.showMultiple();
      }
    },
    changeSelectedImage(id) {
      this.selectedImage = this.images[id].url;
    },
    showMultiple() {
      this.imgs = this.images;
      this.index = this.slideNumber;
      this.show();
    },
    show() {
      this.visible = true;
    },
    handleHide() {
      this.visible = false;
    },
    setNoOfSlidePerPage() {
      this.noOfSlidePerPage = window.innerWidth < 768 ? 1 : 3;
    },
    toggleIsInWishList() {
      this.$emit("changeIsInWishList");
    },
    getGTMItems() {
      let items = {
        item_name: this.product.name,
        item_id: this.product.sku,
        quantity: this.quantity,
        price: this.finalPrice,
      };
      if (this.finalPrice != this.regularPrice) {
        items["discount"] = this.regularPrice - this.finalPrice;
      }
      return items;
    },
    toggleWishItem() {
      if (
        this.$store.getters["wishlist/isProductInWishlist"](this.product.sku) ==
        true
      ) {
        this.$store.dispatch("wishlist/removeProductFromWishlist", {
          sku: this.product.sku,
          parentSku: null,
        });
      } else {
        if (this.product.__typename == "SimpleProduct") {
          this.$store.dispatch("wishlist/addProductToWishlist", {
            sku: this.product.sku,
            parentSku: null,
          });
        } else {
          const msg = {
            type: "danger",
            title: this.$t("wishlist error"),
            text: this.$t("wishlist_select_options"),
          };
          this.$store.dispatch("messages/sendMessage", { message: msg });
          this.$router.push("/" + this.product.url_key);
        }
      }
    }, closeNotifyModal() {
      this.showNotifyModal = false;
    },
  },
};

</script>

<style lang="scss" scoped>
.add-to-wishlist {
  position: absolute;
  font-size: 30px;
  z-index: 9;
  width: 22px;
  height: 28px;

  &:hover {
    text-decoration: none;
  }

  .added {
    color: $red;
  }

  .not-added {
    color: $borderColor;
  }
}
</style>

<!-- <template>
  <div class="product-gallery">
    <div class="selected-image-holder" @click.prevent="showMultiple">
      <div class="actionbuttons ttttes">
        <span class="sale-txt" v-if="onSale == true">Aanbieding</span>
        <div class="new-txt" v-if="onNew == true">Nieuw</div>
      </div>
      <div class="selected-image" :style="{ backgroundImage: `url(${selectedImage})` }"></div>
      <!- <div class="product-view-btn">
        <div class="zoom" @click.prevent="showMultiple">
          <i class="fas fa-search-plus"></i>
        </div>
      </div> ->
    </div>

    <ClientOnly>
      <Lightbox moveDisabled :visible="visible" :imgs="imgs" :index="index" @hide="handleHide">
      </Lightbox>
      <Carousel class="carousel" :autoplay="false" :loop="true" :navigationEnabled="true" :perPage="noOfSlidePerPage"
        :scrollPerPage="false" :paginationEnabled="false" navigationPrevLabel="<i class='fas fa-chevron-left'></i>"
        navigationNextLabel="<i class='fas fa-chevron-right'></i>">
        <Slide v-for="(image, i) of images" :key="`${i}`" :id="`slide ${i}`">
          <b-link href="#" class="slide-img-holder" @click="clickSlideGallery">
            <div class="slide-img" :style="{ backgroundImage: `url(${image.url})` }"></div>
          </b-link>
        </Slide>
      </Carousel>
    </ClientOnly>
  </div>
</template>

<script>
import { isServer } from "@storefront/core/helpers";
import ClientOnly from "vue-client-only";
import { mapGetters } from "vuex";

import { Logger } from "@storefront/core/lib/logger";

export default {
  name: "ProductGallery",
  props: ["images", "isInWishList"],
  components: {
    ClientOnly,
    Carousel: () =>
      !isServer ? import("vue-carousel").then((m) => m.Carousel) : null,
    Slide: () =>
      !isServer ? import("vue-carousel").then((m) => m.Slide) : null,
    Lightbox: () => (!isServer ? import("vue-easy-lightbox") : null),
  },
  data() {
    return {
      selectedImage: this.images[0].url,
      noOfSlidePerPage: 1,
      imgs: "",
      visible: false,
      index: 0,
      slideNumber: 0,
    };
  },
  computed: {
    ...mapGetters({
      product: "product/getCurrentProduct",
    }),
    onSale() {
      return this.product.sale;
    },
    onNew() {
      return this.product.new;
    },
  },
  watch: {
    images: function (oldVal, newVal) {
      Logger.debug("newval[0]", "watch", newVal[0].url)();
      Logger.debug("this.selectedImage", "watch", this.selectedImage)();

      this.selectedImage = newVal[0].url;

      Logger.debug("this.selectedImage", "watch", this.selectedImage)();

      Logger.debug("oldVal", "watch", oldVal[0].url)();
      Logger.debug("newVal", "watch", newVal[0].url)();
      this.selectedImage = oldVal[0].url;
    },
  },
  mounted() {
    window.addEventListener("resize", this.setNoOfSlidePerPage);
    window.addEventListener("load", this.setNoOfSlidePerPage);
    this.setNoOfSlidePerPage();
  },
  destroyed() {
    Logger.debug("destroyed", "ProductGallery", "destroyed is called")();
    window.removeEventListener("resize", this.setNoOfSlidePerPage);
    window.removeEventListener("load", this.setNoOfSlidePerPage);
  },
  methods: {
    clickSlideGallery(e) {
      this.changeSelectedImage(e);
      // if (window.innerWidth < 768) {
      //   this.showMultiple();
      // }
    },
    changeSelectedImage(e) {
      const slideNumber = e.target.parentElement.id.replace(/^\D+/g, "");
      this.slideNumber = +slideNumber;
      this.selectedImage = this.images[slideNumber].url;
    },
    showMultiple() {
      this.imgs = this.images.map(image => image.url);
      this.index = this.slideNumber;
      this.show();
    },
    show() {
      this.visible = true;
    },
    handleHide() {
      this.visible = false;
    },
    setNoOfSlidePerPage() {
      this.noOfSlidePerPage = window.innerWidth < 768 ? 2 : 3;
    },
    toggleIsInWishList() {
      this.$emit("changeIsInWishList");
    },
  },
};
</script>

<style lang="scss" scoped></style>
-->
